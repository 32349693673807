<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <EventHeader v-if="!isEmbedded && event && race" :event="event" :title="race.name" compact/>
      <v-card-title v-if="!isEmbeddedInApp" class="subtitle">{{$t('profile.activities.entry.claim-achievement')}}</v-card-title>
      <v-card-text>
        {{$t('profile.activities.entry.select-achievement')}}
        <v-alert v-if="claimableResponse && !claimableResponse.claimable.length" type="warning">{{$t('profile.activities.entry.no-claimable-achievements-msg')}}</v-alert>
      </v-card-text>

      <div v-if="!claimableResponse" class="text-center">
        <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
      </div>
      <v-list v-if="claimableResponse">
        <v-divider />
        <template v-for="(item, idx) in claimableResponse.claimable">
          <v-list-item :key="idx" selectable :value="item.id" @click="selectBadge(item.id)">
            <v-list-item-icon v-if="item.img"><v-img color="grey" class="mt-1" width="50" style="min-width:60px" :src="item.img"/></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{item.name}}</v-list-item-title>
              <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>fa-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${idx}_sep`" />
        </template>
      </v-list>

      <v-card-text>
        <v-alert v-if="claimableResponse && claimableResponse.hidden_claimables" type="info" class="" outlined>{{$t('profile.activities.entry.hidden-achievements-msg')}}</v-alert>
      </v-card-text>

      <v-card-text v-if="claimableResponse && claimableResponse.claimed.length">     
        <v-expansion-panels accordion class="no-content-padding">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('profile.activities.entry.already-claimed-achievements', { count: claimableResponse.claimed.length})}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item v-for="(item, idx) in claimableResponse.claimed" :key="idx">
                <v-list-item-icon v-if="item.img"><v-img width="25" :src="item.img"/></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import eventService from "@/services/eventService";
import activityService from "@/services/activityService";
import EventHeader from "@/components/EventHeader.vue";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "ClaimAchievement",
  components: {
    VueMarkdown,
    EventHeader,
    DateAndTimeWithTimeZonePicker,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      claimableResponse: null,
      siteData: siteData,
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.entry.claim-achievement'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async loadData() {
      if (this.user) {
        this.event = (await eventService.get(this.$route.params.eventId)).data;
        this.race = this.event.races.find(x => x.id == this.$route.params.raceId);
        this.claimableResponse = (await activityService.getClaimableBadges(this.$route.params.eventId, this.$route.params.raceId)).data;
        console.log('this.claimableResponse', this.claimableResponse);
      }
    },

    async selectBadge(badgeId) {
      this.$router.push({ name: 'activityClaimConfirm', params: { eventId: this.$route.params.eventId, raceId: this.$route.params.raceId }, query: { badgeId: badgeId } });
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
  },

};
</script>
<style lang="scss">
</style>

